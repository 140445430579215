<template>
    <div class="config-property">
        <div>
            Tipo de Menu utilizado:
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="TIPO_MENU" v-model="value.TIPO_MENU">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }} <br>
                    <img :src="tipo.img">
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  import IMG_MENU_COM_ICONES from '../img/MENU_COM_ICONES.png';
  import IMG_MENU_TODOS_DEPARTAMENTOS from '../img/MENU_TODOS_DEPARTAMENTOS.png';
  import IMG_MENU_DEPARTAMENTOS_ICONE_COM_TEXTO from '../img/MENU_DEPARTAMENTOS_ICONE_COM_TEXTO.png';

  export default {
    name: "TIPO_MENU",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Menu com ícones zebrado',
            value: 'MENU_COM_ICONES',
            img: IMG_MENU_COM_ICONES,
          },
          {
            label: 'Menu com nomes dos departamentos',
            value: 'MENU_TODOS_DEPARTAMENTOS',
            img: IMG_MENU_TODOS_DEPARTAMENTOS,
          },
            {
            label: 'Menu com nomes dos departamentos e ícone',
            value: 'MENU_DEPARTAMENTOS_ICONE_COM_TEXTO',
            img: IMG_MENU_DEPARTAMENTOS_ICONE_COM_TEXTO,
          },
        ]
      }
    }
  }
</script>